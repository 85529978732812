import React, { useState, useEffect, useRef, useMemo } from "react";
import { Box, Typography, Grid } from "@mui/material";

function KeyFactsSection() {
  const facts = useMemo(
    () => [
      { label: "Consulting Hours", value: 250 },
      { label: "Projects Completed", value: 10 },
      { label: "Businesses Advised ", value: 100 },
      { label: "Years of Experience", value: 3 },
      // Add more facts as needed
    ],
    []
  );

  const [displayedValues, setDisplayedValues] = useState(facts.map(() => 0));
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const animationDuration = 2000;
          const steps = 60;
          const stepDuration = animationDuration / steps;

          facts.forEach((fact, index) => {
            let currentCount = 0;
            const increment = fact.value / steps;
            const intervalId = setInterval(() => {
              currentCount += increment;
              setDisplayedValues((prevValues) => {
                const newValues = [...prevValues];
                newValues[index] = Math.min(
                  Math.ceil(currentCount),
                  fact.value
                );
                return newValues;
              });

              if (currentCount >= fact.value) {
                clearInterval(intervalId);
              }
            }, stepDuration);
          });
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [facts]);

  return (
    <section id="key-facts" ref={sectionRef}>
      {" "}
      {/* Attach ref to the section */}
      <Box
        sx={{
          maxWidth: 900,
          margin: "0 auto",
          paddingBottom: "40px",
          textAlign: "center",
        }}
      >
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ paddingTop: 5 }}
        >
          {facts.map((fact, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
              sx={{ padding: "20px" }}
            >
              <Typography
                variant="h3"
                align="center"
                sx={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                }}
              >
                {displayedValues[index]}+ {/* Display the animated value */}
              </Typography>
              <Typography variant="body1" align="center" color="text.secondary">
                {fact.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </section>
  );
}

export default KeyFactsSection;

import React from "react";
import { Box, Typography, List, ListItem, Tooltip } from "@mui/material";

import pythonLogo from "../assets/logos/4691213_python_icon.png";
import figmaLogo from "../assets/logos/8134634_logo_figma_icon.png";
import nodejsLogo from "../assets/logos/8666304_node_js_icon.png";
import gcpLogo from "../assets/logos/7123014_cloud_google_icon.png";
import vueLogo from "../assets/logos/9035136_logo_vue_icon.png";
import postmanLogo from "../assets/logos/4691397_postman_icon.png";
import stripeLogo from "../assets/logos/8546990_stripe_icon.png";
import awsLogo from "../assets/logos/8546827_aws_icon.png";
import reactNativeLogo from "../assets/logos/4691292_react native_react_icon.png";
import githubLogo from "../assets/logos/9035112_logo_octocat_icon.png";
import javascriptLogo from "../assets/logos/9035061_logo_javascript_icon.png";
import dockerLogo from "../assets/logos/9034966_logo_docker_icon.png";
import linuxLogo from "../assets/logos/9035069_logo_tux_icon.png";
import firebaseLogo from "../assets/logos/9034973_logo_firebase_icon.png";

function TechnologiesUsed() {
  const technologies = [
    {
      name: "Python",
      logo: pythonLogo,
      description: "Used for scripting, automation, and backend development.",
    },
    {
      name: "JavaScript",
      logo: javascriptLogo,
      description: "Core language for front-end web development.",
    },
    {
      name: "AWS",
      logo: awsLogo,
      description: "Utilised for cloud infrastructure and services.",
    },
    {
      name: "React",
      logo: reactNativeLogo,
      description: "Used for building cross-platform web-based applications.",
    },
    {
      name: "Stripe",
      logo: stripeLogo,
      description: "Integrated for secure payment processing.",
    },
    {
      name: "GitHub",
      logo: githubLogo,
      description: "Used for version control and collaboration.",
    },
    {
      name: "Linux",
      logo: linuxLogo,
      description: "Operating system for development and server environments.",
    },
    {
      name: "Firebase",
      logo: firebaseLogo,
      description:
        "Used for backend services like authentication, database, and storage.",
    },
    {
      name: "Figma",
      logo: figmaLogo,
      description: "Utilised for UI/UX design and prototyping.",
    },
    {
      name: "Node.js",
      logo: nodejsLogo,
      description:
        "Employed for building scalable and efficient server-side applications.",
    },
    {
      name: "GCP",
      logo: gcpLogo,
      description:
        "Leveraged for deploying and managing cloud-based solutions.",
    },
    {
      name: "Vue.js",
      logo: vueLogo,
      description:
        "Used for building dynamic and user-friendly web interfaces.",
    },
    {
      name: "Postman",
      logo: postmanLogo,
      description: "Used for API testing and development.",
    },

    {
      name: "Docker",
      logo: dockerLogo,
      description: "Used for containerisation and deployment.",
    },
  ];

  return (
    <section id="technologies">
      <Box
        sx={{
          margin: "0 auto",
          paddingTop: "40px",
          paddingBottom: "0",
          justifyContent: "center",
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          What I Work With
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Center the list horizontally
            overflowX: "auto",
            // ... (rest of the styles remain the same)
          }}
        >
          <List
            sx={{
              maxWidth: 900,
              display: "flex",
              maxHeight: "100%", // Ensures the list doesn't grow vertically
              overflowX: "auto",
              paddingTop: 5,
              paddingBottom: 2,
              "&::-webkit-scrollbar": {
                height: "5px",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#ffffff",
                borderRadius: "4px",
              },
            }}
          >
            {technologies.map((tech) => (
              <ListItem key={tech.name} sx={{ marginRight: "20px" }}>
                <Tooltip
                  title={tech.name + " - " + tech.description}
                  placement="bottom"
                  arrow
                >
                  <Box
                    component="img"
                    src={tech.logo}
                    alt={tech.name}
                    sx={{
                      maxWidth: "100px",
                      maxHeight: "50px",
                      filter: "grayscale(100%) brightness(0%) invert(1)",
                    }}
                  />
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </section>
  );
}

export default TechnologiesUsed;

import React from "react";
import { Box, Typography, IconButton, Link } from "@mui/material";
import { LinkedIn, GitHub, Email } from "@mui/icons-material"; // Adjust icons as needed

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#333", // Dark background
        color: "white",
        padding: "20px",
        textAlign: "center",
        marginTop: "20px",
      }}
    >
      <Typography variant="body1">&copy; {currentYear} Jacob Alcock</Typography>

      {/* Social Media Links */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <IconButton
          component={Link}
          href="https://www.linkedin.com/in/jacob-alcock/"
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
        >
          <LinkedIn />
        </IconButton>
        <IconButton
          component={Link}
          href="https://github.com/JacobDavidAlcock"
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
        >
          <GitHub />
        </IconButton>
        <IconButton
          component={Link}
          href="mailto:info@jacobalcock.co.uk"
          color="inherit"
        >
          <Email />
        </IconButton>
      </Box>
    </Box>
  );
}

export default Footer;

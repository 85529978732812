// HeroSection.js
import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import "./heroSection.css";
import backgroundVideo from "../assets/8303104-hd_1920_1080_24fps.mp4";

function HeroSection() {
  const [text, setText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.playbackRate = 0.7; // Set playback rate to 0.5 (half speed)
    }
  }, []);

  useEffect(() => {
    const titles = [
      "Cybersecurity Consultant ",
      "Backend Developer",
      "Full Stack Developer",
      "Penetration Tester ",
      "Software Developer",
    ];

    const tick = () => {
      let i = loopNum % titles.length;
      let fullText = titles[i];
      let updatedText = isDeleting
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1);

      setText(updatedText);

      if (!isDeleting && updatedText === fullText) {
        setTypingSpeed(1000); // Pause before deleting (1 second)
        setTimeout(() => {
          setIsDeleting(true);
          setTypingSpeed(25); // Faster deleting speed (25ms)
        }, 1000); // Start deleting after 1 second
      } else if (isDeleting && updatedText === "") {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setTypingSpeed(50);
      }
    };

    const timer = setInterval(() => tick(), typingSpeed);
    return () => clearInterval(timer);
  }, [text, isDeleting, loopNum, typingSpeed]);

  return (
    <section className="hero">
      <video
        className={`hero-video`}
        autoPlay
        loop
        muted
        playsInline
        ref={videoRef}
      >
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="hero-content">
        <Typography variant="h2" component="h1" gutterBottom>
          Hi, I'm Jacob Alcock
        </Typography>
        <span>
          <Typography variant="h5" component="h2" gutterBottom>
            {text}
            <span className={`cursor ${isDeleting ? "blinking" : ""}`}></span>
          </Typography>
        </span>
      </div>
    </section>
  );
}

export default HeroSection;

import React from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link"; // Import HashLink
import "./navbar.css";

function Navbar() {
  const [isTopOfPage, setIsTopOfPage] = React.useState(true);

  React.useEffect(() => {
    const handleScroll = () => {
      setIsTopOfPage(window.scrollY === 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <AppBar
      sx={{
        display: { xs: "none", sm: "block" },
        backgroundColor: isTopOfPage ? "transparent" : "#333",
        boxShadow: isTopOfPage ? "none" : "0 4px 8px rgba(0, 0, 0, 0.1)",
        transition: "background-color 0.2s ease, box-shadow 0.2s ease",
      }}
    >
      <Toolbar>
        <Box>
          <Button
            component={Link}
            to="/#home"
            color="inherit"
            smooth
            sx={{
              ":hover": {
                bgcolor: "primary.main",
                color: "white",
              },
            }}
          >
            {" "}
            {/* Home */}
            Home
          </Button>
          <Button
            component={Link}
            to="/#about"
            color="inherit"
            smooth
            sx={{
              ":hover": {
                bgcolor: "primary.main",
                color: "white",
              },
            }}
          >
            {" "}
            {/* About */}
            About
          </Button>
          <Button
            component={Link}
            to="/#contact"
            color="inherit"
            smooth
            sx={{
              ":hover": {
                bgcolor: "primary.main",
                color: "white",
              },
            }}
          >
            {" "}
            {/* Contact */}
            Contact
          </Button>
          {/* <Button
            component={Link}
            to="/blog"
            color="inherit"
            sx={{
              ":hover": {
                bgcolor: "primary.main",
                color: "white",
              },
            }}
          >
            {" "}
            Blog
          </Button> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;

import React from "react";
import HeroSection from "../components/heroSection";
import { Box, Grid, Typography } from "@mui/material";

import ContactPage from "../components/contact";
import KeyFactsSection from "../components/facts";
import TechnologiesUsed from "../components/skillIcons";
import ServicesOffered from "../components/services";
import SocialProofCarousel from "../components/carousel";

function HomePage() {
  return (
    <Box
      sx={{
        color: "#FFFFFF",
        textAlign: "justify",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
      }}
      justifyContent="center"
      id="home"
    >
      <HeroSection />
      <Grid
        container
        spacing={4}
        id="about"
        justifyContent="center"
        maxWidth={1500}
        width={"90%"}
      >
        <Grid item xs={12}>
          <ServicesOffered />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            About Me
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            I've got a proven track record in both software engineering and
            cybersecurity, I specialise in delivering robust, secure, and
            user-friendly solutions tailored to the unique needs of your
            business. My expertise in full-stack development, coupled with a
            deep understanding of cybersecurity best practices, ensures your
            projects are built with security in mind from the ground up.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            I'm passionate about leveraging technology to empower businesses and
            help them achieve their goals. Whether you need a custom web
            application, a mobile app, or a cloud-based solution, I have the
            skills and experience to bring your vision to life. I've
            successfully delivered projects across a range of industries,
            utilising cutting-edge technologies and frameworks.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <KeyFactsSection />
        </Grid>

        <Grid item xs={12}>
          <SocialProofCarousel />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">
            As a cybersecurity consultant, I understand the importance of
            protecting your digital assets. I follow industry standards to
            ensure your software solutions are resilient against cyber threats.
            I can also help you develop comprehensive security awareness
            programs and conduct technical assessments to identify and mitigate
            vulnerabilities.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            I'm committed to building long-term relationships with my clients,
            providing ongoing support and consultation to ensure your software
            solutions continue to meet your evolving needs. With my technical
            expertise, strong communication skills, and passion for delivering
            results, I'm confident I can be a valuable asset to your team.
            Contact me today to schedule a free consultation.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TechnologiesUsed />
        </Grid>
        <Grid item xs={12}>
          <ContactPage />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomePage;

import * as React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css"; // Import your global styles
import HomePage from "./pages/home";
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Roboto Mono, monospace", // Set default font to Roboto Mono
    h1: {
      fontFamily: "Rubik, sans-serif",
      fontWeight: 700, // Example: Make h1 bold
    },
    h2: {
      fontFamily: "Rubik, sans-serif",
      fontWeight: 500, // Example: Make h2 medium weight
    },
    h3: {
      fontFamily: "Rubik, sans-serif",
    },
    h4: {
      fontFamily: "Rubik, sans-serif",
    },
    h5: {
      fontFamily: "Roboto Mono, sans-serif",
    },
    h6: {
      fontFamily: "Roboto Mono, sans-serif",
    },
  },
  palette: {
    text: {
      primary: "#fff", // Set primary text color to white (same as light theme)
      secondary: "#bdbdbd", // Optional: slightly darker white for secondary text
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: "#121212" }}>
        <Router>
          <Navbar />
          <main>
            <Routes>
              <Route path="/" element={<HomePage />} />
              {/* <Route path="/blog" element={<></>} /> */}
            </Routes>
          </main>
          <Footer />
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;

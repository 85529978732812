import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  MobileStepper,
  Button,
  useTheme,
} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

function SocialProofCarousel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const socialProofItems = [
    {
      type: "award",
      title: "Emerging Talent Finalist 2024",
      description:
        "Recognised as a finalist for The Cyber Emerging Talent Award at the prestigious National Cyber Awards 2024. This honor reflects my dedication to combating digital threats and my passion for advancing the field of cybersecurity. Being selected as a finalist alongside other talented individuals in this industry is a true privilege.",
    },
    {
      type: "quote",
      quote:
        "Jacob's work as a software engineer has been exceptional. His understanding of complex systems and innovative problem-solving skills have consistently contributed to the success of projects. Jacob's code is efficient, reliable and well-documented. I highly recommend Jacob for any software engineering role.",
      author: "Dr Ashley Williams, Manchester Metropolitan University",
    },
    {
      type: "achievement",
      title: "Cyber Scheme Team Member (CSTM)",
      description:
        "I am proud to have earned CSTM, a step to demonstrate my dedication and knowledge in cybersecurity. Passing this assessment, recognised by the National Cyber Security Centre (NCSC), shows my proficiency in both theoretical and practical aspects of penetration testing and cyber security. With this qualification, I am well-equipped to contribute to a team's security efforts and ensure the protection of critical systems and data.",
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) =>
      Math.min(prevActiveStep + 1, socialProofItems.length - 1)
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        flexGrow: 1,
        margin: "auto",
        marginBottom: 10,
        paddingX: 2, // Add horizontal padding for small screens
      }}
    >
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          borderRadius: 2,
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            transition: "transform 0.5s ease", // Smooth transition for horizontal movement
            transform: `translateX(-${activeStep * 100}%)`, // Move items horizontally
            height: "auto", // Allow height to adjust based on content
          }}
        >
          {socialProofItems.map((item, index) => (
            <Box
              key={index}
              sx={{
                flex: "0 0 100%", // Each item takes up 100% of the container width
                padding: 2, // Add padding for content spacing
                boxSizing: "border-box", // Include padding in the width calculation
              }}
            >
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#121212",
                  border: "2px solid white",
                  borderRadius: 2,
                  overflow: "hidden", // Hide overflow to avoid content cutoff
                  height: "100%",
                  boxShadow: theme.shadows[5],
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center", // Center content vertically
                    alignItems: "center", // Center content horizontally
                    height: "100%",
                    textAlign: "center", // Center text horizontally within CardContent
                    overflow: "auto",
                  }}
                >
                  {item.type === "award" && (
                    <>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        align="center"
                      >
                        {item.title}
                      </Typography>
                      <Typography variant="body2" align="center">
                        {item.description}
                      </Typography>
                    </>
                  )}
                  {item.type === "quote" && (
                    <Typography variant="body1" align="center">
                      "{item.quote}"
                      <br />
                      <br />
                      <em>- {item.author}</em>
                    </Typography>
                  )}
                  {item.type === "achievement" && (
                    <>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        align="center"
                      >
                        {item.title}
                      </Typography>
                      <Typography variant="body2" align="center">
                        {item.description}
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
      </Box>

      <MobileStepper
        sx={{
          backgroundColor: "#121212",
          mt: 2, // Margin top for spacing
        }}
        variant="text"
        steps={socialProofItems.length}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === socialProofItems.length - 1}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        }
      />
    </Box>
  );
}

export default SocialProofCarousel;

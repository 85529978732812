import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import Turnstile from "react-turnstile";

function ContactPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [submissionToken, setSubmissionToken] = useState(null);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false); // New state for error Snackbar

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");

  const validateForm = () => {
    let isValid = true;

    if (!name.trim()) {
      setNameError("Name is required.");
      isValid = false;
    } else {
      setNameError("");
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim() || !emailRegex.test(email)) {
      setEmailError("Valid email is required.");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!message.trim()) {
      setMessageError("Message is required.");
      isValid = false;
    } else {
      setMessageError("");
    }

    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    if (!submissionToken) {
      setShowErrorSnackbar(true);
      return;
    }

    setIsLoading(true);

    try {
      const formData = {
        name: name,
        email: email,
        message: message,
        "cf-turnstile-response": submissionToken,
      };

      console.log("formData", formData);

      const response = await fetch(
        "https://contact-form-handler.jacobdavidalcock.workers.dev/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Ensure this header is set
          },
          body: JSON.stringify(formData), // Stringify the formData
        }
      );

      if (response.ok) {
        setOpenSnackbar(true);
        setSubmissionToken(null);
        setName("");
        setEmail("");
        setMessage("");
      } else {
        const errorData = await response.json(); // Attempt to get error details
        console.error("Error submitting form:", errorData);
        // Optionally, display an error message to the user
        setShowErrorSnackbar(true);
      }
    } catch (error) {
      // Handle network error
      console.error("Network error:", error);
      setShowErrorSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setShowErrorSnackbar(false);
  };

  return (
    <section id="contact">
      <Box
        sx={{
          maxWidth: 900,
          margin: "0 auto",
          paddingTop: "40px",
          textAlign: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Get in Touch
        </Typography>

        <TextField
          fullWidth
          label="Name"
          margin="normal"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={!!nameError}
          helperText={nameError}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ffffff",
              },
            },
            mb: 2,
          }}
        />
        <TextField
          fullWidth
          label="Email"
          margin="normal"
          variant="outlined"
          type="email"
          value={email}
          error={!!emailError}
          helperText={emailError}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ffffff",
              },
            },
            mb: 2,
          }}
        />
        <TextField
          fullWidth
          label="Message"
          margin="normal"
          variant="outlined"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          error={!!messageError}
          helperText={messageError}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ffffff",
              },
            },
            mb: 4,
          }}
        />

        <Turnstile
          sitekey="0x4AAAAAAAgzd2u_hjLzGEWY"
          onVerify={(token) => setSubmissionToken(token)}
        />

        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
          sx={{ mt: 2 }}
          onClick={handleSubmit}
        >
          {isLoading ? <CircularProgress size={24} /> : "Send Message"}
        </Button>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            sx={{ width: "100%" }}
          >
            Thank you for your message!
          </Alert>
        </Snackbar>

        {/* Snackbar for Error Message */}
        <Snackbar
          open={showErrorSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="error"
            sx={{ width: "100%" }}
          >
            Please complete the CAPTCHA challenge.
          </Alert>
        </Snackbar>
      </Box>
    </section>
  );
}

export default ContactPage;

import React from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import { Code, RocketLaunch, Cloud } from "@mui/icons-material";

function ServicesOffered() {
  const services = [
    {
      title: "Full-Stack Web Development",
      description:
        "Build robust and secure web applications using modern technologies like React, Node.js, and cloud platforms.",
      icon: <Code />,
    },
    {
      title: "Digital Transformation",
      description:
        "Design, implement, and optimise your digital strategy with cutting-edge technologies and data-driven decision-making.",
      icon: <RocketLaunch />,
    },
    {
      title: "Cloud Infrastructure Management",
      description:
        "Design, deploy, and manage scalable and cost-effective cloud solutions on platforms like AWS and GCP.",
      icon: <Cloud />,
    },
    // Add more services as needed
  ];

  return (
    <section id="services">
      <Box
        sx={{
          maxWidth: 900,
          margin: "0 auto",
          paddingBottom: "40px",
          textAlign: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Services
        </Typography>

        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ paddingTop: 5 }}
        >
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                  },
                  backgroundColor: "#121212",

                  height: "100%",
                  display: "flex",
                  border: "2px solid white",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "30px",
                  }}
                >
                  {React.cloneElement(service.icon, {
                    // Clone the icon and add styles
                    sx: {
                      fontSize: "3rem",
                      color: "#007bff",
                    },
                  })}
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ mt: 2 }}
                  >
                    {service.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </section>
  );
}

export default ServicesOffered;
